<template>
  <div>
    <!-- HIDDEN SM AND DOWN -->
    <div class="hidden-sm-and-down">
      <v-row justify="center">
        <v-col class="info-env pa-2" cols="3">
          <v-avatar :color="green" size="52">
            <span class="white--text headline">1-3</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl123[locale] }}</p>
        </v-col>
        <v-col class="info-env pa-2" cols="3">
          <v-avatar :color="orange" size="52">
            <span class="white--text headline">4-6</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl456[locale] }}</p>
        </v-col>
        <v-col class="info-env pa-2" cols="3">
          <v-avatar :color="purple" size="52">
            <span class="white--text headline">7-9</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl789[locale] }}</p>
        </v-col>
      </v-row>
    </div>

    <!-- HIDDEN MD AND UP -->
    <div class="hidden-md-and-up">
      <v-row justify="center">
        <v-col class="info-env pa-2" cols="12">
          <v-avatar :color="green" size="52">
            <span class="white--text headline">1-3</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl123[locale] }}</p>
        </v-col>
        <v-col class="info-env pa-2" cols="12">
          <v-avatar :color="orange" size="52">
            <span class="white--text headline">4-6</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl456[locale] }}</p>
        </v-col>
        <v-col class="info-env pa-2" cols="12">
          <v-avatar :color="purple" size="52">
            <span class="white--text headline">7-9</span>
          </v-avatar>
          <p class="ma-4">{{ translations.trl789[locale] }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.info-env {
  font-size: 14px;
  color: white;
  text-align: center;
}
</style>

<script>
import { PORTAL_ENVIRONMENTS } from "../constants/translations";

export default {
  name: "TrlBalls",
  data() {
    return {
      translations: PORTAL_ENVIRONMENTS,
      green: "#72bf44",
      orange: "#f7941e",
      purple: "#b37ab4"
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>