import axios from '../axios'

const  EnvironmentsService = (function(){
    
    let instance;
    function Service(){}
    Service.prototype.findAll = function(){
       
        return axios.get(`environments`).then((res) => {
            return res.data;
        }).catch((err) => {
            return {error: err};
        })
    }

    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
    
})()

export default EnvironmentsService
