<template>
    <v-layout justify-center="">
        <v-progress-circular
        :size="70"
        :width="7"
        :color="color ? color : $orange"
        indeterminate
        ></v-progress-circular>
    </v-layout>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        color: {
            type: String,
            required: false
        }
    }
}
</script>