<template>
  <div class="bg global-blue">
    <v-row>
      <v-col>
        <h1 class="my-8 global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</h1>
        <h1 class="my-8 global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
      </v-col>
    </v-row>
    <v-container v-if="isLoading == true">
      <v-row class="hidden-sm-and-down">
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            :label="translations.search[locale]"
            outlined
            hide-details
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <trl-balls></trl-balls>
      <v-row>
        <v-col>
          <Loader></Loader>
        </v-col>
      </v-row>
      <v-row class="hidden-md-and-up">
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            :label="translations.search[locale]"
            outlined
            hide-details
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card
        class="global-blue dark-bg"
        flat
        dark
        min-height="500"
      ></v-card>
    </v-container>
    <v-container v-else>
      <v-row class="hidden-sm-and-down">
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            :label="translations.search[locale]"
            outlined
            hide-details
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <trl-balls></trl-balls>
      <v-row class="hidden-md-and-up">
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            :label="translations.search[locale]"
            outlined
            hide-details
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card
        class="global-blue dark-bg"
        flat
        dark
        min-height="500"
      >
        <v-card-title>
          {{ translations.card_title[locale] }}
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-1"
            text
            :to="{ name: 'info'}"
          >TRL?</v-btn>
        </v-card-title>
        <v-data-table
          class="dark-bg global-blue"
          :headers="headers"
          :items="environments[locale]"
          :search="search"
          :items-per-page="environments[locale].length"
          hide-default-footer
        >
          <template v-slot:[`item.link`]="{ item }">
            <v-btn
              class="ml-n3"
              text
              small
              :to="{ name: 'environmentView', params: { id: item._id} }"
            >{{ locale == 'fi' ? 'Lisätietoja' : 'More' }}</v-btn>
          </template>

          <template v-slot:[`item.trl`]="{ item }">
            <template v-if="item.trl.length > 0">
              <template v-for="(t,i) in 9">
                <template v-if="range(item.trl[0], item.trl[1]).indexOf(i+1) > -1">
                  <v-chip
                    class="ma-1 hidden-md-and-down"
                    v-text="t"
                    :color="getColor(t)"
                    :key="i"
                    dark
                  ></v-chip>
                </template>
                <template v-else>
                  <v-chip
                    class="ma-1 hidden-md-and-down"
                    v-text="t"
                    :color="$blue"
                    :key="i"
                    dark
                  ></v-chip>
                </template>
              </template>
              <p class="trl-md mt-4 hidden-lg-and-up">{{`${item.trl[0]} - ${item.trl[1]}`}}</p>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <error
      :msg="error"
      :open="err"
    ></error>
  </div>
</template>

<style scoped>
.bg {
  padding-bottom: 300px;
  height: 100%;
}
.dark-bg {
  background: var(--v-primary-base) !important;
  padding: 5px;
}
.trl-md {
  font-weight: 600;
}
.title-env {
  font-size: 20px;
  color: white;
}
.emp {
  font-size: 16px;
}
</style>

<script>
import environmentsService from "../../services/environments";
import Loader from "../../components/Loader";
import { PORTAL_ENVIRONMENTS } from "../../constants/translations";
import TrlBalls from "../../components/TrlBalls";

export default {
  name: "Environments",
  components: {
    Loader,
    Error: () => import("../../components/Error"),
    TrlBalls
  },
  data() {
    return {
      isLoading: true,
      environments: {
        fi: [],
        en: []
      },
      categories: [],
      search: "",
      translations: PORTAL_ENVIRONMENTS,
      green: "#72bf44",
      orange: "#f7941e",
      purple: "#b37ab4",
      err: false,
      error: {}
    };
  },
  methods: {
    getColor(trl) {
      return trl <= 3 && trl > 0
        ? this.green
        : trl >= 4 && trl <= 6
        ? this.orange
        : trl >= 7
        ? this.purple
        : this.$blue;
    },
    range(s, e) {
      return Array(e - s + 1)
        .fill()
        .map((_, i) => s + i);
    },
    storage(key, data) {
      if(!data) {
        const cache = window.localStorage.getItem(key);
        if(cache) return cache
        return
      }
      window.localStorage.setItem(key, data);
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    headers() {
      return [
        { text: PORTAL_ENVIRONMENTS.titles[this.locale][0], align: "left", value: "name" },
        { text: PORTAL_ENVIRONMENTS.titles[this.locale][1], value: "field" },
        { text: PORTAL_ENVIRONMENTS.titles[this.locale][2], value: "trl" },
        { text: PORTAL_ENVIRONMENTS.titles[this.locale][3], value: "organization" },
        { text: PORTAL_ENVIRONMENTS.titles[this.locale][4], value: "link" }
      ];
    }
  },
  watch: {
    error(val) {
      // console.log("err obj", val);
    }
  },
  created() {

    const environmentsCache = this.storage('environments_portal')
    if(environmentsCache){
      this.environments = JSON.parse(environmentsCache)
      this.isLoading = false
    } 

    environmentsService
      .getInstance()
      .findAll()
      .then(environments => {

   /*      console.log(environments) */

        if (environments.error) {

          this.err = true;
          this.error = {
            fi: "Kehitysympäristöjen hakeminen epäonnistui",
            en: "There was an error with fetching the data",
            err: environments.error.response.status
          };

          return;
        }

        this.environments.fi = environments.map(e => {
          return e.fi;
        });

        this.environments.en = environments.map(e => {
          return e.en;
        });

        this.isLoading = false;
        this.storage('environments_portal', JSON.stringify(this.environments))
      })
      .catch(err => {
        this.error = {
          fi: "Kehitysympäristöjen hakeminen epäonnistui",
          en: "There was an error with fetching the data",
          err
        };
      })
  }
};
</script>
